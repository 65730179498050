<template>
  <div>
    <v-row>
      <v-col class="d-flex" cols="12" md="5">
        <app-text-search-field v-model="searchParams.text" @filter="showFilter = !showFilter" />
      </v-col>
      <v-col>
        <v-btn class="text-capitalize mr-2" color="primary" @click="select()">
          Pesquisar
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center" v-if="showFilter">
      <v-col cols="12" md="3">
        <ClassificacaoFiscalSelect
          v-model="searchParams.classificacao_fiscal"
        />
      </v-col>
      <v-col cols="12" md="3">
        <CategoriesSearch
          @input="handleRelationshipInput($event, 'category_id')"
        />
      </v-col>
      <v-col cols="12" md="3">
        <BrandsSearch @input="handleRelationshipInput($event, 'brand_id')" />
      </v-col>
      <v-col cols="12" md="3">
        <SupplierSearch
          @input="handleRelationshipInput($event, 'supplier_id')"
        />
      </v-col>

      <v-col cols="12" md="3">
        <app-number-field
          v-model="searchParams.available_stock_more_than"
          :allowNegative="true"
          :forceZero="false"
          label="Estoque maior que"
        />
      </v-col>
      <v-col cols="12" md="3">
        <app-number-field
          v-model="searchParams.available_stock_less_than"
          :allowNegative="true"
          :forceZero="false"
          label="Estoque menor que"
        />
      </v-col>
      <v-col cols="12" md="3">
        <app-select
          @input="setOrder($event)"
          :items="orderByOptions"
          :item-text="(val) => val.text"
          :item-value="(val) => val"
          label="Ordenar por"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-chip-group v-model="searchParams.status" column multiple>
          <v-chip filter :value="1" color="primary">Ativo</v-chip>
          <v-chip filter :value="0">Inativo</v-chip>
        </v-chip-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3">
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Valor de estoque (custo)
              <br />
              <b class="text-h5">
                {{ $format.decimal(productsSum.cost_stock_sum) }}
              </b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3">
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text class="d-flex align-center justify-space-between">
            <div>
              Valor de estoque (venda)
              <br />
              <b class="text-h5">
                {{ $format.decimal(productsSum.price_cash_stock_sum) }}
              </b>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="products.data"
          :server-items-length="products.total"
          :options.sync="options"
          hide-default-footer
          @update:options="handleTableUpdate($event)"
        >

          <template v-slot:[`item.cost`]="{ item }">
            {{ $format.decimal(item.cost) }}
          </template>
          <template v-slot:[`item.price_cash`]="{ item }">
            {{ $format.decimal(item.price_cash) }}
          </template>
          <!-- available_stock_sum  -->
          <template v-slot:[`item.available_stock_sum`]="{ item }">
            {{ $format.decimal(item.available_stock_sum) }}
          </template>
          <template v-slot:[`item.physic_stock_sum`]="{ item }">
            {{ $format.decimal(item.physic_stock_sum) }}
          </template>
          <template v-slot:[`item.cost_stock_sum`]="{ item }">
            {{ $format.decimal(item.cost_stock_sum) }}
          </template>
          <template v-slot:[`item.price_cash_stock_sum`]="{ item }">
            {{ $format.decimal(item.price_cash_stock_sum) }}
          </template>
        </v-data-table>
        <v-pagination
          v-model="searchParams.page"
          :length="products.last_page"
          @input="select($event)"
          :total-visible="9"
        ></v-pagination>
        <!-- <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="20"
          hide-default-footer
        ></v-data-table> -->

        <!-- 
          cod
          produto 
          quantidade estoque
          preco custo
          total

          somar valor estoque
         -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CompanyChip from "@/components/company/sections/CompanyChip.vue";
import ClassificacaoFiscalSelect from "@/components/product/ui/ClassificacaoFiscalSelect";
import BrandsSearch from "@/components/brands/ui/BrandsSearch.vue";
import SupplierSearch from "@/components/suppliers/ui/SupplierSearch.vue";
import CategoriesSearch from "@/components/categories/ui/CategoriesSearch.vue";

export default {
  components: {
    CompanyChip,
    ClassificacaoFiscalSelect,
    BrandsSearch,
    SupplierSearch,
    CategoriesSearch,
  },

  data: () => ({
    headers: [
      {
        text: "Cod.",
        align: "start",
        sortable: false,
        value: "code",
      },
      {
        text: "Descrição",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Marca",
        align: "start",
        sortable: false,
        value: "brand.name",
      },
      {
        text: "Custo",
        value: "cost",
        sortable: false,
        align: "right",
        width: "10%",
      },
      {
        text: "Preço Av",
        value: "price_cash",
        sortable: false,
        align: "right",
        width: "10%",
      },
      {
        text: "Est. Disponível",
        value: "available_stock_sum",
        sortable: false,
        align: "right",
        width: "10%",
      },
      {
        text: "Est. Físico",
        value: "physic_stock_sum",
        sortable: false,
        align: "right",
        width: "10%",
      },
      {
        text: "Total Custo",
        value: "cost_stock_sum",
        sortable: false,
        align: "right",
        width: "10%",
      },
      {
        text: "Total Preço Av",
        value: "price_cash_stock_sum",
        sortable: false,
        align: "right",
        width: "10%",
      },
    ],

    showFilter: false,

    products: {
      data: [],
    },

    productsSum: {
      cost_stock_sum: 0,
      price_cash_stock_sum: 0,
    },

    options: {},

    orderByOptions: [
      { text: "Código", order_by: "cod", order_direction: "asc" },
      { text: "Descrição", order_by: "name", order_direction: "asc" },
      { text: "Preço", order_by: "price_cash" },
      {
        text: "Total Estoque (menor)",
        order_by: "price_cash_stock_sum",
        order_direction: "asc",
      },
      {
        text: "Total Estoque (maior)",
        order_by: "price_cash_stock_sum",
        order_direction: "desc",
      },
    ],

    searchParams: {
      text: null,
      status: [1],
      classificacao_fiscal: "00",
      category_id: null,
      supplier_id: null,
      brand_id: null,
      page: 1,
      order_by: "name",
      order_direction: "asc",
    },
  }),

  created() {},

  methods: {
    select(page = 1) {
      this.searchParams.page = page;
      this.$loading.start();
      this.$http
        .index("product/product-report", this.searchParams)
        .then((response) => {
          this.$loading.finish();
          this.products = response.products;
          this.productsSum = response.productsSum;
        });
    },

    setOrder(order) {
      this.searchParams.order_by = order.order_by;
      this.searchParams.order_direction = order.order_direction;
    },

    handleRelationshipInput(relationship, field) {
      if (relationship) {
        this.searchParams[field] = relationship.id;
      } else {
        this.searchParams[field] = null;
      }
    },

    handleTableUpdate(options) {
      this.options = options;
      this.searchParams.orderBy = options.sortBy[0];
      this.searchParams.orderDirection = options.sortDesc[0] ? "desc" : "asc";
      //this.select(options.page);
    },

    handleSearchInput(text) {},
  },
};
</script>

<style></style>
